import React from 'react';

import {
  EOrderReason, EOrderStatus, ERoles, TOrder
} from 'types';
import strings from 'constants/localization';
import Tooltip from 'components/library/Tooltip';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import { get } from 'lodash';
import css from './ReadyToPrint.module.css';
import { copyTextToClipboard } from '../../../../helpers';

const getTitle = (order: TOrder) => {
  const { reason } = order;

  const lines = [
    strings.formatString(strings.orderPageReasonOfRejection, reason),
  ];

  if (reason === EOrderReason.ADDRESS_ISSUE) {
    const contact = get(order, 'sourceData.buyer');
    if (contact) {
      const contactLines = Object.values(contact).join('\n');
      lines.push(contactLines);
    }
  }

  return lines.join('\n\n');
};

interface IReadyToPrint {
  order: TOrder,
  role: ERoles,
}
const ReadyToPrint: React.FC<IReadyToPrint> = ({ order, role }) => {
  if (EOrderStatus.CREATED === order.status) {
    return null;
  }

  if (EOrderStatus.ISSUE === order.status) {
    if (!order.reason) {
      return null;
    }
    const title = getTitle(order);
    return (
      <Tooltip title={title}>
        <Icon type={E_ICON_TYPE.info} className={css.iconInfo} />
      </Tooltip>
    );
  }

  if (role === ERoles.CUSTOMER) {
    return null;
  }

  return order.orderSheetStatus && order.orderSheetUrl
    ? (
      <div className={css.iconContainer}>
        {
          order.sourceData?.shipment?.tracking && (
            <Tooltip title={order.sourceData?.shipment?.tracking}>
              <Icon
                type={E_ICON_TYPE.barcode}
                className={css.iconInfo}
                onClick={e => {
                  e.stopPropagation();
                  copyTextToClipboard(order.sourceData?.shipment?.tracking || '');
                }}
              />
            </Tooltip>
          )
        }
        <a
          role="button"
          aria-label="ready to print"
          href={order.orderSheetUrl}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <Icon type={E_ICON_TYPE.readyToPrint} />
        </a>
      </div>
    )
    : <Icon type={E_ICON_TYPE.notReadyToPrint} />;
};

export default ReadyToPrint;
