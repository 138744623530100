import React from 'react';
import { IIconProps, E_ICON_TYPE, T_MAP_TYPE } from './types';
import IconLogin from './IconLogin';
import IconLogo from './IconLogo';
import IconLogoWithTextBlue from './IconLogoWithTextBlue';
import IconClosedEye from './IconClosedEye';
import IconOpenEye from './IconOpenEye';
import IconMark from './IconMark';
import IconEmail from './IconEmail';
import IconDone from './IconDone';
import IconCorrect from './IconCorrect';
import IconHome from './IconHome';
import IconOrders from './IconOrders';
import IconChat from './IconChat';
import IconCustomers from './IconCustomers';
import IconLogout from './IconLogout';
import IconProfile from './IconProfile';
import IconManagers from './IconManagers';
import IconClose from './IconClose';
import IconError from './IconError';
import IconBlocked from './IconBlocked';
import IconDescription from './IconDescription';
import IconOption from './IconOption';
import IconAdd from './IconAdd';
import IconSquaredChat from './IconSquaredChat';
import IconNoStatistics from './IconNoStatistics';
import IconNoSKU from './IconNoSKU';
import IconNoResults from './IconNoResults';
import IconNoOrders from './IconNoOrders';
import IconNoCustomers from './IconNoCustomers';
import IconNoInvoices from './IconNoInvoices';
import IconSomeError from './IconSomeError';
import IconFilter from './IconFilter';
import IconUnlocked from './IconUnlocked';
import IconBlockedLock from './IconBlockedLock';
import IconSearch from './IconSearch';
import IconDelete from './IconDelete';
import IconBlock from './IconBlock';
import IconUnblock from './IconUnblock';
import IconList from './IconList';
import IconGrid from './IconGrid';
import IconCart from './IconCart';
import IconBag from './IconBag';
import IconDots from './IconDots';
import IconCopy from './IconCopy';
import IconMail from './IconMail';
import IconPhone from './IconPhone';
import IconFlagEu from './IconFlagEu';
import IconFlagGe from './IconFlagGe';
import IconFlagRu from './IconFlagRu';
import IconFlagPt from './IconFlagPt';
import IconFlagUK from './IconFlagUK';
import IconFlagUSA from './IconFlagUSA';
import IconUserRegistered from './IconUserRegistered';
import IconLineArrowRight from './IconLineArrowRight';
import IconReadyToPrint from './IconReadyToPrint';
import IconNotReadyToPrint from './IconNotReadyToPrint';
import IconNoPdf from './IconNoPdf';
import IconPdf from './IconPdf';
import IconUpload from './IconUpload';
import IconInfo from './IconInfo';
import IconFile from './IconFile';
import IconVerticalDivider from './IconVerticalDivider';
import IconStaticProduct from './IconStaticProduct';
import IconVariableProduct from './IconVariableProduct';
import IconCode from './IconCode';
import IconHand from './IconHand';
import IconImage from './IconImage';
import IconText from './IconText';
import IconNumbering from './IconNumbering';
import IconColorPicker from './IconColorPicker';
import IconBackgroundColorPicker from './IconBackgroundColorPicker';
import IconAlignLeft from './IconAlignLeft';
import IconAlignRight from './IconAlignRight';
import IconAlignCenterV from './IconAlignCenterV';
import IconAlignCenterH from './IconAlignCenterH';
import IconAlignTop from './IconAlignTop';
import IconAlignBottom from './IconAlignBottom';
import IconTextAlignRight from './IconTextAlignRight';
import IconTextAlignLeft from './IconTextAlignLeft';
import IconTextAlignCenter from './IconTextAlignCenter';
import IconTextAlignJustify from './IconTextAlignJustify';
import IconOpacity from './IconOpacity';
import IconAltLocked from './IconAltLocked';
import IconAltUnLocked from './IconAltUnLocked';
import IconTextUppercase from './IconTextUppercase';
import IconTextDefault from './IconTextDefault';
import IconTextLowercase from './IconTextLowercase';
import IconTextItalic from './IconTextItalic';
import IconTextBold from './IconTextBold';
import IconTextUnderline from './IconTextUnderline';
import IconTextSpacing from './IconTextSpacing';
import IconTextCharSpacing from './IconTextCharSpacing';
import IconTextLineHeight from './IconTextLineHeight';
import IconBorderSize from './IconBorderSize';
import IconNext from './IconNext';
import IconBack from './IconBack';
import IconHeight from './IconHeight';
import IconRotate from './IconRotate';
import IconArrowUp from './IconArrowUp';
import IconSKUs from './IconSKUs';
import IconWarning from './IconWarning';
import IconTextCapitalize from './IconTextCapitalize';
import IconTicket from './IconTicket';
import IconRemoveBackground from './IconRemoveBackground';
import IconDuplicate from './IconDuplicate';
import IconReprocess from './IconReprocess';
import IconExport from './IconExport';
import IconBarcode from './IconBarcode';

const MAP:T_MAP_TYPE = {
  [E_ICON_TYPE.logo]: props => <IconLogo {...props} />,
  [E_ICON_TYPE.logoWithText]: props => <IconLogoWithTextBlue {...props} />,
  [E_ICON_TYPE.login]: props => <IconLogin {...props} />,
  [E_ICON_TYPE.closedEye]: props => <IconClosedEye {...props} />,
  [E_ICON_TYPE.openEye]: props => <IconOpenEye {...props} />,
  [E_ICON_TYPE.mark]: props => <IconMark {...props} />,
  [E_ICON_TYPE.email]: props => <IconEmail {...props} />,
  [E_ICON_TYPE.done]: props => <IconDone {...props} />,
  [E_ICON_TYPE.correct]: props => <IconCorrect {...props} />,
  [E_ICON_TYPE.home]: props => <IconHome {...props} />,
  [E_ICON_TYPE.orders]: props => <IconOrders {...props} />,
  [E_ICON_TYPE.chat]: props => <IconChat {...props} />,
  [E_ICON_TYPE.customers]: props => <IconCustomers {...props} />,
  [E_ICON_TYPE.logout]: props => <IconLogout {...props} />,
  [E_ICON_TYPE.profile]: props => <IconProfile {...props} />,
  [E_ICON_TYPE.managers]: props => <IconManagers {...props} />,
  [E_ICON_TYPE.SKUs]: props => <IconSKUs {...props} />,
  [E_ICON_TYPE.close]: props => <IconClose {...props} />,
  [E_ICON_TYPE.error]: props => <IconError {...props} />,
  [E_ICON_TYPE.blocked]: props => <IconBlocked {...props} />,
  [E_ICON_TYPE.description]: props => <IconDescription {...props} />,
  [E_ICON_TYPE.option]: props => <IconOption {...props} />,
  [E_ICON_TYPE.add]: props => <IconAdd {...props} />,
  [E_ICON_TYPE.squaredChat]: props => <IconSquaredChat {...props} />,
  [E_ICON_TYPE.noStatistics]: props => <IconNoStatistics {...props} />,
  [E_ICON_TYPE.noSKU]: props => <IconNoSKU {...props} />,
  [E_ICON_TYPE.noResults]: props => <IconNoResults {...props} />,
  [E_ICON_TYPE.noOrders]: props => <IconNoOrders {...props} />,
  [E_ICON_TYPE.noCustomers]: props => <IconNoCustomers {...props} />,
  [E_ICON_TYPE.noManagers]: props => <IconNoCustomers {...props} />,
  [E_ICON_TYPE.noInvoices]: props => <IconNoInvoices {...props} />,
  [E_ICON_TYPE.someError]: props => <IconSomeError {...props} />,
  [E_ICON_TYPE.filter]: props => <IconFilter {...props} />,
  [E_ICON_TYPE.unlocked]: props => <IconUnlocked {...props} />,
  [E_ICON_TYPE.locked]: props => <IconBlockedLock {...props} />,
  [E_ICON_TYPE.search]: props => <IconSearch {...props} />,
  [E_ICON_TYPE.delete]: props => <IconDelete {...props} />,
  [E_ICON_TYPE.block]: props => <IconBlock {...props} />,
  [E_ICON_TYPE.unblock]: props => <IconUnblock {...props} />,
  [E_ICON_TYPE.list]: props => <IconList {...props} />,
  [E_ICON_TYPE.grid]: props => <IconGrid {...props} />,
  [E_ICON_TYPE.cart]: props => <IconCart {...props} />,
  [E_ICON_TYPE.bag]: props => <IconBag {...props} />,
  [E_ICON_TYPE.dots]: props => <IconDots {...props} />,
  [E_ICON_TYPE.copy]: props => <IconCopy {...props} />,
  [E_ICON_TYPE.mail]: props => <IconMail {...props} />,
  [E_ICON_TYPE.phone]: props => <IconPhone {...props} />,
  [E_ICON_TYPE.flagEu]: props => <IconFlagEu {...props} />,
  [E_ICON_TYPE.flagGe]: props => <IconFlagGe {...props} />,
  [E_ICON_TYPE.flagRu]: props => <IconFlagRu {...props} />,
  [E_ICON_TYPE.flagPt]: props => <IconFlagPt {...props} />,
  [E_ICON_TYPE.flagUK]: props => <IconFlagUK {...props} />,
  [E_ICON_TYPE.flagUSA]: props => <IconFlagUSA {...props} />,
  [E_ICON_TYPE.userRegistered]: props => <IconUserRegistered {...props} />,
  [E_ICON_TYPE.notReadyToPrint]: props => <IconNotReadyToPrint {...props} />,
  [E_ICON_TYPE.readyToPrint]: props => <IconReadyToPrint {...props} />,
  [E_ICON_TYPE.noPdf]: props => <IconNoPdf {...props} />,
  [E_ICON_TYPE.pdf]: props => <IconPdf {...props} />,
  [E_ICON_TYPE.upload]: props => <IconUpload {...props} />,
  [E_ICON_TYPE.info]: props => <IconInfo {...props} />,
  [E_ICON_TYPE.file]: props => <IconFile {...props} />,
  [E_ICON_TYPE.verticalDivider]: props => <IconVerticalDivider {...props} />,
  [E_ICON_TYPE.staticProduct]: props => <IconStaticProduct {...props} />,
  [E_ICON_TYPE.variableProduct]: props => <IconVariableProduct {...props} />,
  [E_ICON_TYPE.code]: props => <IconCode {...props} />,
  [E_ICON_TYPE.hand]: props => <IconHand {...props} />,
  [E_ICON_TYPE.image]: props => <IconImage {...props} />,
  [E_ICON_TYPE.text]: props => <IconText {...props} />,
  [E_ICON_TYPE.numbering]: props => <IconNumbering {...props} />,
  [E_ICON_TYPE.colorPicker]: props => <IconColorPicker {...props} />,
  [E_ICON_TYPE.backgroundColorPicker]: props => <IconBackgroundColorPicker {...props} />,
  [E_ICON_TYPE.alignLeft]: props => <IconAlignLeft {...props} />,
  [E_ICON_TYPE.alignRight]: props => <IconAlignRight {...props} />,
  [E_ICON_TYPE.alignCenterV]: props => <IconAlignCenterV {...props} />,
  [E_ICON_TYPE.alignCenterH]: props => <IconAlignCenterH {...props} />,
  [E_ICON_TYPE.alignTop]: props => <IconAlignTop {...props} />,
  [E_ICON_TYPE.alignBottom]: props => <IconAlignBottom {...props} />,
  [E_ICON_TYPE.textAlignRight]: props => <IconTextAlignRight {...props} />,
  [E_ICON_TYPE.textAlignLeft]: props => <IconTextAlignLeft {...props} />,
  [E_ICON_TYPE.textAlignCenter]: props => <IconTextAlignCenter {...props} />,
  [E_ICON_TYPE.textAlignJustify]: props => <IconTextAlignJustify {...props} />,
  [E_ICON_TYPE.opacity]: props => <IconOpacity {...props} />,
  [E_ICON_TYPE.altLocked]: props => <IconAltLocked {...props} />,
  [E_ICON_TYPE.altUnLocked]: props => <IconAltUnLocked {...props} />,
  [E_ICON_TYPE.textUppercase]: props => <IconTextUppercase {...props} />,
  [E_ICON_TYPE.textLowercase]: props => <IconTextLowercase {...props} />,
  [E_ICON_TYPE.textCapitalize]: props => <IconTextCapitalize {...props} />,
  [E_ICON_TYPE.ticket]: props => <IconTicket {...props} />,
  [E_ICON_TYPE.textDefault]: props => <IconTextDefault {...props} />,
  [E_ICON_TYPE.textItalic]: props => <IconTextItalic {...props} />,
  [E_ICON_TYPE.textBold]: props => <IconTextBold {...props} />,
  [E_ICON_TYPE.textUnderline]: props => <IconTextUnderline {...props} />,
  [E_ICON_TYPE.textSpacing]: props => <IconTextSpacing {...props} />,
  [E_ICON_TYPE.textCharSpacing]: props => <IconTextCharSpacing {...props} />,
  [E_ICON_TYPE.textLineHeight]: props => <IconTextLineHeight {...props} />,
  [E_ICON_TYPE.borderSize]: props => <IconBorderSize {...props} />,
  [E_ICON_TYPE.next]: props => <IconNext {...props} />,
  [E_ICON_TYPE.back]: props => <IconBack {...props} />,
  [E_ICON_TYPE.height]: props => <IconHeight {...props} />,
  [E_ICON_TYPE.rotate]: props => <IconRotate {...props} />,
  [E_ICON_TYPE.lineArrowRight]: props => <IconLineArrowRight {...props} />,
  [E_ICON_TYPE.arrowUp]: props => <IconArrowUp {...props} />,
  [E_ICON_TYPE.warning]: props => <IconWarning {...props} />,
  [E_ICON_TYPE.removeBackground]: props => <IconRemoveBackground {...props} />,
  [E_ICON_TYPE.duplicate]: props => <IconDuplicate {...props} />,
  [E_ICON_TYPE.reprocess]: props => <IconReprocess {...props} />,
  [E_ICON_TYPE.export]: props => <IconExport {...props} />,
  [E_ICON_TYPE.barcode]: props => <IconBarcode {...props} />,
};

function Icon(props:IIconProps) {
  const { type } = props;
  return MAP[type](props);
}

export const ICON_TYPES = E_ICON_TYPE;
export default Icon;
