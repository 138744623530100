import { TIntegrationConnection, TIntegrationEventBind } from 'types/Integration';
import { TFormikOptionComment } from 'components/PageArticles/AddEditArticle/components/ArticleForm/constants';
import { IRadioOption } from 'components/library/Radio';
import { IPdfDocument } from '../components/PageArticles/components/Editor/contexts/EditorContext';
import fabric from '../components/PageArticles/components/Editor/helpers/fabric';
import { ECodeObjectTitle } from '../components/PageArticles/components/Editor/components/Viewport/fabric/codeObject';
import {
  TExcelBindings
} from '../components/PageArticles/components/Editor/components/Toolbar/components/IntegrationMatching/ChooseTicketModalContent/components/ExcelModal/types';

export type TLocalizedConstString = () => string;

export enum EPathKeyType {
  LIST,
  ADD,
  EDIT,
}

export type TRoutesLocalization = {
  [key: string]: {
    [key in EPathKeyType]: TLocalizedConstString
  }
}

export enum ELanguages {
  EN = 'en',
  DE = 'de',
  RU = 'ru',
}

export enum EUnits {
  MM = 'mm',
  CM = 'cm',
}

export enum EOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
  SQUARE = 'square',
}

export enum ECanvasObjectTypes {
  TEXT = 'TextObject',
  IMAGE = 'ImageObject',
  CODE = 'CodeObject',
  NUMBERING = 'NumberingObject',
}

export enum EPermissions {
  CUSTOMER = 'customer',
  PRINTER = 'printer',
  MANAGER = 'manager',
  ADMIN = 'admin',
  // meta = remove / block user
  EDIT_SELF_META = 'edit_self_meta',
  EDIT_ADMIN_META = 'edit_admin_meta',
  EDIT_PRINTER_META = 'edit_printer_meta',
  EDIT_MANAGER_META = 'edit_manager_meta',
  EDIT_CUSTOMER_META = 'edit_customer_meta',
  CHANGE_ORDER_STATUS = 'change_order_status', // 3: "changeOrderStatus"
  ASSIGN_MANAGERS = 'assign_managers', // 4: "reassignManagers"
  VIEW_PRINTER_CUSTOMERS = 'view_printer_customers',
  EDIT_PRINTER_CUSTOMERS = 'edit_printer_customers',
  VIEW_AND_EDIT_PRINTER_CUSTOMERS = 'edit_printer_customers',
  VIEW_MANAGER_CUSTOMERS = 'view_manager_customers', // 2: "viewOwn"
  EDIT_MANAGER_CUSTOMERS = 'edit_manager_customers', // 1: "editOwn"
  // oAuth app permissions
  APP_EVENTBRITE = 'app_eventbrite',
}
export type TRoleSelf = 'SELF'
export const ROLE_SELF:TRoleSelf = 'SELF';

export enum ERoles {
  CUSTOMER = 'customer',
  PRINTER = 'printer',
  MANAGER = 'manager',
  ADMIN = 'admin',
}

export enum EIntegrationServices {
  EVENTBRITE = 'eventbrite',
  CODE_KARUSSEL = 'codeKarussel',
  PAYLOGIC = 'paylogic',
  MANUAL = 'manual',
  API = 'api',
}

/* PRINT ITEMS */
export enum EPrintItemSizeNumberFields {
  WIDTH = 'width',
  HEIGHT = 'height',
  BLEED = 'bleed',
}

export enum EPrintItemSizeStringFields {
  ID = 'id',
  KEY = 'key',
  NAME = 'name',
}

export enum EPrintItemNestedFields {
  QUANTITIES = 'quantities',
  PAPERS = 'papers',
}

export enum EPrintItemCommentFields {
  QUANTITY_COMMENT = 'quantityComment',
  PAPER_COMMENT = 'paperComment',
}
/* PRINT ITEMS */

/* ARTICLES */
export enum EProductTypes {
  VD_PRODUCT = 'vdProduct',
  STATIC_PRODUCT = 'staticProduct',
}

export enum ETemplateSides {
  FRONT = 'front',
  BACK = 'back'
}
/* ARTICLES */

export enum ETablePageViews {
  TABLE = 'table',
  GRID = 'grid',
}

export enum EFooterSaveButtonDisplayModes {
  ALWAYS = 'always',
  ONLY_ON_LAST_TAB = 'onlyOnLastTab',
}

/* COMMON/ABSTRACT */
// make only given field optional (unlike Partial<>)
export type PartialBy<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

// make only one field not optional
export type PartialExcludingOne<T, K extends keyof T> = Pick<T, K> & Partial<T>;
/* COMMON/ABSTRACT */

export type TIncludedCustomer = Pick<TUser, 'id' | 'name' | 'surname' | 'email' | 'companyName' | 'phoneNumber'> & {}

export type TOrderItem = { id: string, title: string, items: TParam[] }
export type TOrderSourceData = {
  serviceOrderId: string,
  ticketsQuantity: number,
  eventName: string,
  ticketTypeName: string,
  shipment: {
    address: string,
    address2: string,
    city: string,
    company: string,
    country: string,
    gender: string,
    name: string,
    region: string,
    zip: string,
    tracking?: string,
  }
}
export type TOrder = {
  id: string,
  title: string,
  reason: string,
  quantityId?: string,
  paperId?: string,
  customerId?: string,
  pspId?: string,
  options?: { [key:string]: TOption | undefined }
  quantity?: TQuantity | null,
  paper?: TPaper | null,
  price: number,
  readyToPrint: boolean,
  orderSheetStatus: boolean,
  currency: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string,
  orderComment: string,
  status: EOrderStatus,
  orderSheetUrl: string | null,
  manager: Pick<TUser, 'id' | 'name' | 'surname' | 'blocked' | 'companyName'>,
  customer: Pick<TUser, 'id' | 'name' | 'surname' | 'blocked' | 'companyName'>,
  orderItems: TOrderItem[],
  article: TArticle,
  linkToFront?: string,
  linkToBack?: string,
  groupId: string | null,
  source: EIntegrationServices | 'web',
  sourceData: TOrderSourceData | null,
}

export type TUser = {
  id: string,
  // personal info
  role: ERoles,
  name: string,
  surname: string,
  email: string | null,
  avatar: string | null,
  phoneNumber: string,
  fullProfile: boolean,
  lang: ELanguages,
  // meta
  currency: '€',
  confirmed: boolean,
  deleted: boolean,
  blocked: boolean,
  printerBlocked: boolean,
  skuPreview?: boolean,
  pspId: string,
  manager?: TUser,
  managerId: string | null,
  lastLoginAt: Date | null,
  // permissions
  userPermissions: EPermissions[],
  // stats
  articleStats?: TStat<EArticleStatuses>[],
  orderStats?: TStat<EOrderStatus>[],
  // company info
  companyName: string,
  position: string | null,
  sendOrderSheetsTo: string | null,
  customersCount?: number,
  customers?: TIncludedCustomer[],
  // integration
  integration?: Partial<TIntegrationConnection> | null,
  eventBind?: Partial<TIntegrationEventBind> | null,
};

export type TPageSizeOptionsItem = {
  label: number,
  value: number,
}

export type TSelectUnit = {
  value: EUnits,
  label: EUnits,
};

export type TPageWithTableResponse<Rows> = {
  count: number,
  rows: Rows[],
};

/* PRINT ITEMS */
export type TPrintItemSize<Value = string> = PartialExcludingOne<Record<EPrintItemSizeStringFields, string>, EPrintItemSizeStringFields.NAME>
  & Record<EPrintItemSizeNumberFields, Value> & { order?: number | null };

export type TPrintItemNestedField<Value = string> = {
  id?: string,
  printItemId?: string,
  optionId?: string,
  key?: string,
  value: Value,
  order: number | null,
  label?: string, // for selects
};
// TODO: use common type that is now TPrintItemOption
export type TPrintItemOptionPortfolio = {
  id?: string,
  printItemId?: string,
  key: string,
  name: string,
  comment: string,
  order: number | null,
  values: TPrintItemNestedField[],
}

export type TPrintItemOptionalPricing<Value = string> = {
  id?: string,
  printItemId?: string,
  key: string,
  price: Value,
  currency: '€',
  order: number | null,
  option: TPrintItemNestedField,
  quantity: TPrintItemNestedField<Value>,
}

export type TPrintItemPricing<Value = string> = {
  id?: string,
  key: string,
  price: Value,
  currency: '€',
  size: TPrintItemSize<Value>,
  paper: TPrintItemNestedField,
  quantity: TPrintItemNestedField<Value>,
  order: number | null,
  units: EUnits
}

export type TPrintItemInformation<Value = string> = {
  title: string,
  description: string,
  units: EUnits,
  paperComment: string,
  quantityComment: string,
  quantities: TPrintItemNestedField<Value>[],
  papers: TPrintItemNestedField[],
  sizes: TPrintItemSize<Value>[],
}

export type TPrintItemsItemNotSaved<Value = string> = {
  options: TPrintItemOptionPortfolio[],
  pricings: TPrintItemPricing<Value>[],
  optionalPricings: TPrintItemOptionalPricing<Value>[]
} & TPrintItemInformation<Value>;

export type TPrintItemsItem<Value = string> = {
  id: string;
  pspId: string;
  createdAt: string;
  updatedAt: string;
} & TPrintItemsItemNotSaved<Value>

export type TPrintItemForm<Value = string> = {
  itemInformation: TPrintItemInformation<Value>,
  options: TPrintItemOptionPortfolio[],
  pricings: TPrintItemPricing<Value>[],
  optionalPricings: TPrintItemOptionalPricing<Value>[],
}
/* PRINT ITEMS */

export type TAddCustomerModalForm = {
  name: string,
  surname: string,
  companyName: string,
  email?: string,
  sendOrderSheetsTo: string,
};
/* CUSTOMERS */

/* ARTICLES */
export type TArticle = {
  id: string,
  copyIndex: number | null,
  originalId: string | null,
  currency: string,
  productType: EProductTypes,
  title: string,
  status: EArticleStatuses,
  pspId: string,
  customerId: string,
  frontSide: TArticleDocument,
  backSide: TArticleDocument,
  skuIdTitle: string,
  description: string,
  special: boolean,
  paperComment: string,
  selectedSizeComment: string,
  printItemId: string,
  selectedSizeId: string,
  printItem: TPrintItem,
  size: TSize | null,
  options: TOption[]
  quantities: TQuantity[],
  papers: TPaperQuantity[],
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  integrationEventBindId: string | null,
  numberingStartValue: number | null,
}

// export type TArticleTemplate = {
//   id: string,
//   path: string,
//   thumbnail: string,
//   description: string,
//   side: ETemplateSides
// }

export type TGetArticlesItemSelectedPaper = {
  id: string,
  quantities: TPrintItemNestedField[],
}

export type TGetArticlesItemTemplate = {
  id: string,
  articleId: string,
  description: string,
  deleted: boolean,
  side: ETemplateSides,
  path: string, // s3 link
  thumbnail: string, // s3 link
  createdAt: string,
  updateAt: string,
};

// TODO: merge this type with TArticle
export type TGetArticlesItem = {
  // ids
  copyIndex: TArticle['copyIndex'],
  originalId: TArticle['originalId'],
  id: string,
  pspId: string,
  customerId: string,
  printItemId: string,
  selectedSizeId: string,
  frontSide?: TArticleDocument,
  backSide?: TArticleDocument,
  // meta
  title: string,
  skuIdTitle: string,
  description: string,
  paperComment: string,
  selectedSizeComment: string,
  productType: EProductTypes,
  status: EArticleStatuses,
  createdAt: string,
  updateAt: string | null,
  deletedAt: string | null,
  currency: string,
  integrationEventBindId: string | null,
  integrationMatchings: TIntegrationMatchings | null,
  integrationTicketType: string | null,
  numberingStartValue: TArticle['numberingStartValue'],
  // nested arrays
  templates: TGetArticlesItemTemplate[],
  papers: TGetArticlesItemSelectedPaper[],
};

export type TArticleStatusOption = {
  label: TLocalizedConstString,
  value: EArticleStatuses
}

export type TArticleCreateUpdate = {
  copyIndex: TArticle['copyIndex'],
  originalId: TArticle['originalId'],
  title: string,
  skuIdTitle: string,
  description: string,
  status: EArticleStatuses,
  selectedSizeId: string,
  selectedPapers: string[],
  selectedQuantities: {
    qtyId: string,
    paperId: string
  }[],
  selectedOptionValues: string[],
  selectedSizeComment: string,
  paperComment: string,
  optionComments: TFormikOptionComment[],
  printItemId: string,
  customerId: string,
  currency: string,
  canvas: TArticleDocument[],
  integrationEventBindId: string | null,
  numberingStartValue: TArticle['numberingStartValue'],
  special: boolean,
};
/* ARTICLES */

export enum EArticleStatuses {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum EOrderStatus {
  CREATED = 'created',
  RECEIVED = 'received',
  SEND_TO_PRINTER = 'send to printer',
  IN_PRINTING = 'in printing',
  IN_DELIVERY = 'in delivery',
  ASSUMED_DELIVERED = 'assumed delivered',
  ISSUE = 'issue',
  ARCHIVED = 'archived',
}

export enum EOrderReason {
  IN_DELIVERY = 'in delivery',
  PDF_ISSUE = 'pdf-issue',
  ADDRESS_ISSUE = 'address-issue',
}

export type TStat<T extends EOrderStatus | EArticleStatuses> = { status: T, count: number };

export interface IPaginationParams {
  skip?: number,
  limit?: number,
  sort?: [string, number],
}

export type TFilterBlocked = IRadioOption<boolean | undefined>;

export type TRowSelection = Record<number, boolean>

export type TPaper = {
  id: string,
  value: string,
  description?: string,
  order?: number,
  printItemId?: string
}

export type TPaperQuantity = {
  id: string,
  quantities: TQuantity[],
  paper: TPaper
}

export type TSize = {
  id: string,
  description?: string,
  height: number,
  width: number,
  bleed: number,
  name: string,
  value?: string,
  order?: number,
  printItemId?: string
}

export type TQuantity = {
  id: string,
  value: number | string,
  order?: number,
  printItemId?: string
}

export type TOptionValue = {
  id: string,
  value: string,
  order?: number,
  optionId?: string
}

export type TPrintItemOption = {
  id: string,
  name: string,
  order?: number,
  comment?: string,
  printItemId?: string,
  values: TOptionValue[]
}

export type TOption = {
  id: string,
  option: {
    id: string,
    name: string,
  },
  comment?:string,
  value?: TOptionValue
  values?: TOptionValue[]
}

export type TOptionalPricingItem = {
  id: string,
  currency: string,
  price: string | number,
  option: TOptionValue,
  quantity: TQuantity,
  order?: number,
  printItemId?: string
}

export type TPricingItem = {
  id: string,
  currency: string,
  price: string | number,
  quantity: TQuantity,
  size: TSize,
  paper: TPaper,
  order?: number,
  printItemId?: string
}

export type TPrintItem = {
  id: string,
  pspId: string,
  description: string,
  quantityComment: string
  paperComment: string,
  units: string,
  deletedAt: null | string,
  createdAt: string,
  updatedAt: string,
  papers: TPaper[],
  options: TPrintItemOption[],
  optionalPricings: TOptionalPricingItem[],
  pricings: TPricingItem[],
  quantities: TQuantity[],
  sizes: TSize[],
  title: string,
}

export type TParam = {
  id: string | number,
  name: string,
  type: ECanvasObjectTypes,
  title: ECodeObjectTitle,
  subtype?: string,
  src?: string,
  content?: string,
  width?: number,
  height?: number,
  scaleX?:number,
  scaleY?:number
  barcodeHeight?: number,
  locked?: boolean,
  margin?: number,
  text?: string,
  url?: string,
  side?: ETemplateSides,
  isDefaultValue?: boolean,
  dpi?:number,
  startValue: number,
  textCase?:ETextCase
}

export interface IEventbriteTicket {
  id: string,
  event_id: string,
  name: string,
  display_name: string,
  description: string,
  category: string, // TODO: fill enum
  actual_cost: number | null,
  actual_fee: number | null,
  fee: number | null,
  tax: number | null,
  donation: boolean,
  free: boolean,
  minimum_quantity: number,
  maximum_quantity: number,
  maximum_quantity_per_order: number,
  capacity: number,
  quantity_total: number,
  quantity_sold: number,
  sales_start: string,
  sales_end: string,
  hidden: boolean,
  include_fee: boolean,
  hide_description: boolean,
  hide_sale_dates: boolean,
  on_sale_status: string, // TODO: fill enum
  has_pdf_ticket: boolean,
  order_confirmation_message: string | null,
  delivery_methods: any, // TODO: fill enum array
  sales_channels: any, // TODO: fill enum array
}

export interface IPaylogicTicket {
  product_id: number,
  product_uid: string,
  product_name: string,
  product_subtitle: string,
  state: string, // TODO: enum
  price_cur: string, // TODO: enum
  price_amt: string,
  type: string,
  min_per_order: string,
  max_per_order: string,
  pool_title: string,
  pool_size: string,
  vat_rate: string,
  psf_currency: string, // TODO: enum
  psf_amount: string,
  ppf_currency: string, // TODO: enum
  ppf_amount: string,
}

export type TIntegrationMatchingsFieldsItem = {
  [key: string]: string,
}

export type TExcelMatchings = {
  bindings: TExcelBindings | null,
  columns: { index: number, label: string }[]
}

export type TIntegrationMatchings = {
  service: EIntegrationServices | null,
  ticketId: string | null,
  ticketName: string | null,
  fields: TIntegrationMatchingsFieldsItem,
  excel?: TExcelMatchings,
}

export type TArticleDocument = {
  canvas: {
    version: string,
    objects: typeof fabric.CodeObject[] | typeof fabric.ImageObject[] | typeof fabric.TextObject[],
    backgroundImage: {
      [key: string]: any,
      src: string
    },
    integrationMatchings?: TIntegrationMatchings,
  },
  side: ETemplateSides,
  document: IPdfDocument,
  thumbnail: string,
}

export type TSizeDimensions = {width: number, height:number}

export enum ETextCase {
  Uppercase = 'uppercase',
  Lowercase = 'lowercase',
  Capitalize = 'Capitalize',
  Default = 'Default',
}
