import {
  LegacyRef, ReactElement, SVGProps
} from 'react';

export enum E_ICON_TYPE {
  logo = 'logo',
  logoWithText = 'logoWithText',
  login = 'login',
  closedEye = 'closedEye',
  openEye = 'openEye',
  mark = 'mark',
  email = 'email',
  done = 'done',
  correct = 'correct',
  profile = 'profile',
  managers = 'managers',
  SKUs = 'SKUS',
  customers = 'customers',
  orders = 'orders',
  home = 'home',
  chat = 'chat',
  logout = 'logout',
  close = 'close',
  error = 'error',
  blocked = 'blocked',
  description = 'description',
  option = 'option',
  add = 'add',
  squaredChat = 'squaredChat',
  noStatistics = 'noStatistics',
  noSKU = 'noSKU',
  noOrders = 'noOrders',
  noCustomers = 'noCustomers',
  noManagers = 'noManagers',
  noInvoices = 'noInvoices',
  noResults = 'noResults',
  noPdf = 'noPdf',
  someError = 'someError',
  filter = 'filter',
  unlocked = 'unlocked',
  locked = 'locked',
  search = 'search',
  delete = 'delete',
  block = 'block',
  unblock = 'unblock',
  list = 'list',
  grid = 'grid',
  cart = 'cart',
  bag = 'bag',
  dots = 'dots',
  copy = 'copy',
  phone = 'phone',
  mail = 'mail',
  flagEu = 'flagEu',
  flagGe = 'flagGe',
  flagRu = 'flagRu',
  flagPt = 'flagPt',
  flagUK = 'flagUK',
  flagUSA = 'flagUSA',
  userRegistered = 'userRegistered',
  readyToPrint = 'readyToPrint',
  notReadyToPrint = 'notReadyToPrint',
  pdf = 'pdf',
  upload = 'upload',
  info = 'info',
  file = 'file',
  verticalDivider = 'verticalDivider',
  staticProduct = 'staticProduct',
  variableProduct = 'variableProduct',
  code = 'code',
  hand = 'hand',
  image = 'image',
  text = 'text',
  numbering = 'numbering',
  colorPicker = 'colorPicker',
  backgroundColorPicker = 'backgroundColorPicker',
  alignLeft = 'alignLeft',
  alignRight = 'alignRight',
  alignCenterV = 'alignCenterV',
  alignCenterH = 'alignCenterH',
  alignTop = 'alignTop',
  alignBottom = 'alignBottom',
  textAlignLeft = 'textAlignLeft',
  textAlignRight = 'textAlignRight',
  textAlignCenter = 'textAlignCenter',
  textAlignJustify = 'textAlignJustify',
  opacity = 'opacity',
  altLocked = 'altLocked',
  altUnLocked = 'altUnLocked',
  textUppercase = 'textUppercase',
  textLowercase = 'textLowercase',
  textCapitalize = 'textCapitalize',
  ticket = 'ticket',
  textDefault = 'textDefault',
  textItalic = 'textItalic',
  textBold = 'textBold',
  textUnderline = 'textUnderline',
  textSpacing = 'textSpacing',
  textCharSpacing = 'textCharSpacing',
  textLineHeight = 'textLineHeight',
  borderSize = 'borderSize',
  next = 'next',
  back = 'back',
  height = 'height',
  rotate = 'rotate',
  lineArrowRight = 'lineArrowRight',
  arrowUp = 'arrowUp',
  warning = 'warning',
  removeBackground = 'removeBackground',
  duplicate = 'duplicate',
  reprocess = 'reprocess',
  export = 'export',
  barcode = 'barcode'
}

export type T_MAP_TYPE = {
  [key in E_ICON_TYPE]: (props: IIconProps) => ReactElement
}

export interface IIconProps extends SVGProps<SVGSVGElement>{
  type: E_ICON_TYPE,
  passedRef?: LegacyRef<SVGSVGElement>
}
